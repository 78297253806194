import { useColorScheme } from '@mui/material';
import { useEffect } from 'react';
import { useMedia } from 'react-use';

import { useSettings } from '@core/hooks/useSettings';

const ModeChanger = () => {
  // Hooks
  const { setMode } = useColorScheme();
  const { settings } = useSettings();
  const isDark = useMedia('(prefers-color-scheme: dark)', false);

  useEffect(() => {
    if (settings.mode) {
      if (settings.mode === 'system') {
        setMode(isDark ? 'dark' : 'light');
      } else {
        setMode(settings.mode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.mode]);

  return null;
};

export default ModeChanger;
