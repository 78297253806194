'use client';

import {
  CssBaseline,
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import { useMemo, type PropsWithChildren } from 'react';
import type {} from '@mui/material/themeCssVarsAugmentation'; //! Do not remove this import otherwise you will get type errors while making a production build
import type {} from '@mui/lab/themeAugmentation'; //! Do not remove this import otherwise you will get type errors while making a production build
import { useMedia } from 'react-use';

import { useSettings } from '@core/hooks/useSettings';
import defaultCoreTheme from '@core/theme';
import type { SystemMode } from '@core/types';

import themeConfig from '@/configs/themeConfig';

import ModeChanger from './ModeChanger';

type Props = PropsWithChildren<{
  systemMode: SystemMode;
}>;

const ThemeProvider = (props: Props) => {
  // Props
  const { children, systemMode } = props;

  // Vars
  const isServer = typeof window === 'undefined';
  let currentMode: SystemMode;

  // Hooks
  const { settings } = useSettings();
  const isDark = useMedia('(prefers-color-scheme: dark)', false);

  if (isServer) {
    currentMode = systemMode;
  } else {
    if (settings.mode === 'system') {
      currentMode = isDark ? 'dark' : 'light';
    } else {
      currentMode = settings.mode!;
    }
  }

  // Merge the primary color scheme override with the core theme
  const theme = useMemo(() => {
    const coreTheme = defaultCoreTheme(currentMode);
    return extendTheme(coreTheme);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMode]);

  return (
    <AppRouterCacheProvider
      options={{
        prepend: true,
      }}
    >
      <CssVarsProvider
        theme={theme}
        defaultMode={systemMode}
        modeStorageKey={`${themeConfig.templateName.toLowerCase().split(' ').join('-')}-mui-template-mode`}
      >
        <>
          <ModeChanger />
          <CssBaseline />
          {children}
        </>
      </CssVarsProvider>
    </AppRouterCacheProvider>
  );
};

export default ThemeProvider;
