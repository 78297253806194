'use client';

import {
  createContext,
  useCallback,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';

export interface VerticalNavState {
  width?: number;
  collapsedWidth?: number;
  isCollapsed?: boolean;
  isHovered?: boolean;
  collapsing?: boolean; // for internal use only
  transitionDuration?: number;
}

export type VerticalNavContextProps = VerticalNavState & {
  updateVerticalNavState: (values: VerticalNavState) => void;
  hoverVerticalNav: (value?: VerticalNavState['isHovered']) => void;
};

const VerticalNavContext = createContext({} as VerticalNavContextProps);

export const VerticalNavProvider = ({ children }: PropsWithChildren) => {
  // States
  const [verticalNavState, setVerticalNavState] = useState<VerticalNavState>({
    isCollapsed: true,
    isHovered: false,
  });

  // Hooks
  const updateVerticalNavState = useCallback(
    (values: Partial<VerticalNavState>) => {
      setVerticalNavState((prevState) => ({
        ...prevState,
        ...values,
        collapsing: values.isCollapsed === true,
      }));
    },
    []
  );

  const hoverVerticalNav = useCallback((value?: boolean) => {
    setVerticalNavState((prevState) => ({
      ...prevState,
      isHovered: value !== undefined ? Boolean(value) : !prevState?.isHovered,
    }));
  }, []);

  const verticalNavProviderValue = useMemo(
    () => ({
      ...verticalNavState,
      updateVerticalNavState,
      hoverVerticalNav,
    }),
    [verticalNavState, updateVerticalNavState, hoverVerticalNav]
  );

  return (
    <VerticalNavContext.Provider value={verticalNavProviderValue}>
      {children}
    </VerticalNavContext.Provider>
  );
};

export default VerticalNavContext;
