// MUI Imports
import type { Theme } from '@mui/material';

const accordion = (): Theme['components'] => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        marginBottom: '2px',
        '&': {
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <i className='ri-arrow-down-s-line' />,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3, 5),
        paddingLeft: '40px',
        paddingRight: '40px',
        fontWeight: '500',
        color: 'var(--mui-palette-text-primary)',
        backgroundColor: 'var(--mui-palette-background-default)',
        '&.Mui-expanded': {
          minHeight: 48,
        },
        '& .MuiTypography-root': {
          color: 'inherit',
          fontWeight: theme.typography.fontWeightMedium,
        },
      }),
      content: {
        margin: '0 !important',
      },
      expandIconWrapper: {
        color: 'var(--mui-palette-text-primary)',
        fontSize: '1.25rem',
        '& i, & svg': {
          fontSize: 'inherit',
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: () => ({
        padding: '24px 40px',
        '& .MuiTypography-root': {
          color: 'var(--mui-palette-text-secondary)',
        },
      }),
    },
  },
});

export default accordion;
