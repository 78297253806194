'use client';

// React Imports
import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';

// Type Imports
import { useObjectCookie } from '@core/hooks/useObjectCookie';
import type { Mode } from '@core/types';

// Config Imports
import themeConfig from '@/configs/themeConfig';

// Hook Imports

// Settings type
export interface Settings {
  mode?: Mode;
}

// UpdateSettingsOptions type
interface UpdateSettingsOptions {
  updateCookie?: boolean;
}

// SettingsContextProps type
interface SettingsContextProps {
  settings: Settings;
  updateSettings: (
    settings: Partial<Settings>,
    options?: UpdateSettingsOptions
  ) => void;
  isSettingsChanged: boolean;
  resetSettings: () => void;
  updatePageSettings: (settings: Partial<Settings>) => () => void;
}

interface Props {
  children: ReactNode;
  settingsCookie: Settings | null;
  mode?: Mode;
}

// Initial Settings Context
export const SettingsContext = createContext<SettingsContextProps | null>(null);

// Settings Provider
export const SettingsProvider = (props: Props) => {
  // Initial Settings
  const initialSettings: Settings = {
    mode: themeConfig.mode,
  };

  const updatedInitialSettings = {
    ...initialSettings,
    mode: props.mode || themeConfig.mode,
  };

  // Cookies
  const [settingsCookie, updateSettingsCookie] = useObjectCookie<Settings>(
    themeConfig.settingsCookieName,
    JSON.stringify(props.settingsCookie) !== '{}'
      ? props.settingsCookie
      : updatedInitialSettings
  );

  // State
  const [_settingsState, _updateSettingsState] = useState<Settings>(
    JSON.stringify(settingsCookie) !== '{}'
      ? settingsCookie
      : updatedInitialSettings
  );

  const updateSettings = (
    settings: Partial<Settings>,
    options?: UpdateSettingsOptions
  ) => {
    const { updateCookie = true } = options || {};

    _updateSettingsState((prev) => {
      const newSettings = { ...prev, ...settings };

      // Update cookie if needed
      if (updateCookie) updateSettingsCookie(newSettings);

      return newSettings;
    });
  };

  /**
   * Updates the settings for page with the provided settings object.
   * Updated settings won't be saved to cookie hence will be reverted once navigating away from the page.
   *
   * @param settings - The partial settings object containing the properties to update.
   * @returns A function to reset the page settings.
   *
   * @example
   * useEffect(() => {
   *     return updatePageSettings({ theme: 'dark' });
   * }, []);
   */
  const updatePageSettings = (settings: Partial<Settings>): (() => void) => {
    updateSettings(settings, { updateCookie: false });

    // Returns a function to reset the page settings
    return () => updateSettings(settingsCookie, { updateCookie: false });
  };

  const resetSettings = () => {
    updateSettings(initialSettings);
  };

  const isSettingsChanged = useMemo(
    () => JSON.stringify(initialSettings) !== JSON.stringify(_settingsState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_settingsState]
  );

  return (
    <SettingsContext.Provider
      value={{
        settings: _settingsState,
        updateSettings,
        isSettingsChanged,
        resetSettings,
        updatePageSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};
