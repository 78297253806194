import type { Theme } from '@mui/material';
import { Inter } from 'next/font/google';

import type { SystemMode } from '@core/types';

import colorSchemes from './colorSchemes';
import customShadows from './customShadows';
import overrides from './overrides';
import shadows from './shadows';
import spacing from './spacing';
import typography from './typography';

const inter = Inter({
  subsets: ['latin'],
  weight: ['300', '400', '500', '600', '700', '800', '900'],
});

const theme = (mode: SystemMode): Theme => {
  return {
    components: overrides(),
    colorSchemes: colorSchemes(),
    ...spacing,
    shape: {
      borderRadius: 4,
      customBorderRadius: {
        xs: 2,
        sm: 4,
        md: 6,
        lg: 8,
        xl: 10,
      },
    },
    shadows: shadows(mode),
    typography: typography(inter.style.fontFamily),
    customShadows: customShadows(mode),
    mainColorChannels: {
      light: '46 38 61',
      dark: '231 227 252',
      lightShadow: '46 38 61',
      darkShadow: '19 17 32',
    },
  } as Theme;
};

export default theme;
